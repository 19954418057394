import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/style/theme.scss";

import {
  ConfigProvider,
  Dialog,
  Toast,
  Button,
  Form,
  Field,
  CellGroup,
  Radio,
  RadioGroup,
  DatePicker,
  Popup,
  Uploader,
  Tab,
  Tabs,
  Icon
} from "vant";
import "vant/lib/index.css";

const app = createApp(App);

app.use(ConfigProvider);
app.use(Toast);
app.use(Button);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Radio);
app.use(RadioGroup);
app.use(DatePicker);
app.use(Popup);
app.use(Uploader);
app.use(Tab);
app.use(Tabs);
app.use(Dialog);
app.use(Icon);
app.use(router);
app.mount("#app");
