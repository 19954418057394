import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "登记页面" },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
  },
  {
    path: "/authorization",
    name: "authorizaiton",
    meta: { title: "鉴权" },
    query: {
      code: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "authorizaiton" */ "../views/authorizaiton/index.vue"
      ),
  },
  {
    path: "/detail",
    name: "detail",
    meta: { title: "登记详情页" },
    query: {
      userid: "",
    },
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/detail/index.vue"),
  },
  {
    path: "/user",
    name: "user",
    meta: { title: "个人信息" },
    query: {
      userid: "",
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  next(); //执行进入路由，如果不写就不会进入目标页
});

export default router;
